<template>
  <div class="pt-6 rounded-xl bg-[#FCFCFC]">
    <div class="text-sm text-center text-black border-b border-gray-200 flex justify-between">
      <ul class="flex flex-nowrap -mb-px font-bold">
        <li v-for="(item, index) in filters.length > 0 ? filters : defaultFilters" :key="index" class="me-2">
          <a
            href="#"
            @click="filtrarDados(index)"
            :class="{
              'inline-block p-4 border-b-4 border-wewa-orange rounded-t-lg active': index === filtroAtual,
              'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:border-gray-300 dark:hover:text-gray-300': index !== filtroAtual,
            }"
          >
            {{ item.texto }}
            <span v-if="item.valor !== undefined">({{ countFilteredRows(item) }})</span>
          </a>
        </li>
      </ul>
      <div class="pr-3">
        <input
          type="text"
          v-model="termoPesquisa"
          class="bg-[#f0f0f0] border border-white text-black text-base rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
          placeholder="Fazer uma pesquisa..."
        />
      </div>
    </div>

    <!-- Conditional rendering for no data message -->
    <div v-if="filteredRows.length === 0" class="py-10 flex justify-center items-center">
      <p>Nenhum dado encontrado para os filtros aplicados.</p>
    </div>

    <div v-else class="pb-3 overflow-x-auto relative">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-lg text-black bg-gray-50">
          <tr class="bg-white border-b">
            <th v-for="(column, index) in columns" :key="index" class="py-3 px-6 text-nowrap">
              {{ column }}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in filteredRows" :key="rowIndex" class="bg-white border-b">
            <td v-for="(value, colIndex) in row"  :key="colIndex" class="py-4 px-6">
              {{ value }}
            </td>
            <td class="font-semibold text-base text-black flex flex-nowrap items-center mt-5">
              <router-link v-if="showView" :to="`/${urlPrefix}/show/${rows[rowIndex][idKey]}`" class="hover:underline pr-1">Ver</router-link>
              <router-link v-if="showUpdate" :to="`/${urlPrefix}/edit/${rows[rowIndex][idKey]}`" class="hover:underline pr-1">Atualizar</router-link>
              <button v-if="showDelete" class="text-red-600 hover:underline pr-1" @click="confirmDeleteUser(rows[rowIndex][idKey])">Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <modal :show="showModal" @close="showModal = false">
    <div class="text-left">
      <h2 class="text-xl font-bold mb-4 uppercase">Deseja eliminar este registro?</h2>
      <p class="mb-4">Clique no "sim", para confirmar este evento, e atualizar os dados.</p>
      <div class="grid grid-flow-col justify-stretch">
        <CustomButton :style="'red'" label="Não" @click="showModal = false" />
        <CustomButton :style="''" label="Sim" @click="deleteUser" class="ml-3" />
      </div>
    </div>
  </modal>

  <modal :show="showModal2" @close="showModal2 = false">
    <div class="text-left">
      <h2 class="text-xl font-bold mb-4 uppercase">Atualizar Dados do perfil?</h2>
      <p class="mb-4">Clique no "sim", para confirmar este evento, e atualizar os dados.</p>
      <CustomButton :style="'orange'" label="Entedido" @click="showModal2 = false" />
    </div>
  </modal>
</template>

<script>
import modal from "./modal/Modal";
import CustomButton from "./CustomButton.vue";
import axios from "axios";

export default {
  components: {
    modal,
    CustomButton,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [], 
    },
    defaultFilters: {
      type: Array,
      default: () => [
        { texto: "Todos", valor: "" },
        { texto: "Eliminados", valor: "eliminado" },
      ],
    },
    filterKey: {
      type: String,
      default: "id",
    },
    idKey: {
      type: String,
      default: "id",
    },
    urlPrefix: {
      type: String,
      default: "",
    },
    urlPrefixDelete: {
      type: String,
      default: "",
    },
    showView: {
      type: Boolean,
      default: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filtroAtual: 0,
      termoPesquisa: "",
      showModal: false,
      showModal2: false,
      userIdToDelete: null,
    };
  },
  computed: {
    filteredRows() {
      const filtro = this.filters.length > 0 ? this.filters[this.filtroAtual] : this.defaultFilters[this.filtroAtual];
      const searchTerm = this.termoPesquisa.toLowerCase();

      return this.limitedItems.filter(row => {
        const matchesFilter = filtro.valor === "" || row[filtro.valor] === true;
        const matchesSearch = Object.values(row).some(value =>
          String(value).toLowerCase().includes(searchTerm)
        );
        return matchesFilter && matchesSearch;
      });
    },
    limitedItems() {
      // return this.rows;
      const data = this.rows.map(item => {
        
        
        const entries = Object.entries(item); // Convert object to an array of key-value pairs
        const limitedEntries = entries.slice(0, this.columns.length); // Take only the first 'limit' key-value pairs
        return Object.fromEntries(limitedEntries); 
      })
      return data;
    },
  },
  methods: {
    filtrarDados(index) {
      this.filtroAtual = index;
    },
    countFilteredRows(filtro) {
      const searchTerm = this.termoPesquisa.toLowerCase();

      return this.rows.filter(row => {
        const matchesFilter = filtro.valor === "" || row[filtro.valor] === true;
        const matchesSearch = Object.values(row).some(value =>
          String(value).toLowerCase().includes(searchTerm)
        );
        return matchesFilter && matchesSearch;
      }).length;
    },
    confirmDeleteUser(userId) {
      this.userIdToDelete = userId;
      this.showModal = true;
    },
    async deleteUser() {
      try {
        this.showModal = "true";
        console.log
        if (this.userIdToDelete) {
          const token = localStorage.getItem("token");
          if (!token) {
            console.log("Token não encontrado no localStorage");
            return;
          }
          const customHeaderParams = { Authorization: `Bearer ${token}` };
          const response = await axios.delete(`${this.urlPrefix}s/${this.userIdToDelete}`, { headers: customHeaderParams });
          if (response.status === 200 && response.data.success === true) {
            this.userIdToDelete = null;
            this.showModal = false;
            window.location.reload();
          }
        }
      } catch (error) {
        console.log("Erro ao eliminar este Registro:", error);
      }
    },
    async listUserCount() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("Token não encontrado no localStorage");
          return;
        }
        const customHeaderParams = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`users`, { headers: customHeaderParams });
        if (response.status === 200 && response.data.success === true) {
          this.users = response.data.data.map((index) => ({
            tableid: index + 1,
          }));
        } else {
          console.log("Algo deu errado, tente mais tarde...");
        }
      } catch (error) {
        console.error("Erro:", error);
      }
    }
  },
  mounted() {
    this.listUserCount();
  }
};
</script>

<style scoped></style>
