<template>
  <div class="m-4">
    <TitleComponent
      title="Clientes"
      subtitle="Tenha o controle de todos os seus Clientes."
      textButton="+ Novo"
      showButton="true"
      :routerLink="{ name: 'user_register' }"
    />

    <div v-if="isLoading" class="flex justify-center items-center py-10">
      <InnerPreLoaderComponent />
    </div>
    
    <div v-else>
      <TableComponent :columns="colunas" :rows="currentUsers" :urlPrefix="urlPrefix" :filters="filters" :filterKey="filterKey" :idKey="idKey" />

      <div class="pagination flex flex-row items-center justify-center gap-4 py-5">
        <button class="bg-white text-wewa-orange px-3 py-2 rounded-lg" @click="prevPage" :disabled="currentPage === 1">
          Anterior
        </button>
        <span>{{ currentPage }} de {{ totalPages }}</span>
        <button class="bg-white text-wewa-orange px-3 py-2 rounded-lg" @click="nextPage" :disabled="currentPage === totalPages">
          Próxima
        </button>
      </div>
    </div>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <div class="text-left">
        <h2 class="text-xl font-bold mb-4 uppercase">{{errorMessage.code}}</h2>
        <p class="mb-4">{{ errorMessage.message }}</p>
        <CustomButton :style="'orange'" label="Entendido" @click="showErrorModal = false" />
      </div>
    </modal>
  </div>
</template>

<script>
import TableComponent from "../../components/ClientTableComponent.vue";
import TitleComponent from "../../components/TitleComponent.vue";
import InnerPreLoaderComponent from "../../components/preLoader/InnerPreLoader.vue"; 
import CustomButton from "@/components/CustomButton.vue";
import modal from "@/components/modal/Modal.vue";
import axios from "axios";

export default {
  components: {
    TableComponent,
    TitleComponent,
    InnerPreLoaderComponent,
    modal, 
    CustomButton
  },
  data() {
    return {
      colunas: ["Nome", "Email", "Telefone"],
      users: [],
      currentUsers: [],
      filters: [],
      filterKey: 'id',
      idKey: 'id',
      urlPrefix: 'client',
      currentPage: 1,
      usersPerPage: 10,
      totalPages: 0,
      isLoading: false,
      errorMessage: {
        code: "",
        message: ""
      },
      showErrorModal: false
    };
  },
  methods: {
    async listUsers() {
      this.isLoading = true;
      this.errorMessage.code = "";
      this.errorMessage.message = "";
      this.showErrorModal = false;

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          this.errorMessage.code = "Erro de autenticação";
          this.errorMessage.message = "Token não encontrado. Por favor, faça login novamente.";
          this.showErrorModal = true;
          await this.$router.push("/");
          return;
        }

        const customHeaderParams = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(`clients`, {
          headers: customHeaderParams,
        });

        if (response.status === 200 && response.data.success === true) {
          console.log(response.data.data)
          this.users = response.data.data.map((user) => ({
            full_name:
              user.client.first_name && user.client.last_name
                ? user.client.first_name.length + user.client.last_name.length > 15
                  ? (user.client.first_name + " " + user.client.last_name).substring(0, 15) +
                    "..."
                  : user.client.first_name + " " + user.client.last_name
                : "",
            email: user.client.email
              ? user.client.email.substring(0, 18) +
                (user.client.email.length > 18 ? "..." : "")
              : "",
            phone_number: user.client.phone_number,
            id: user.id,
          }));

          this.totalPages = Math.ceil(this.users.length / this.usersPerPage);
          this.updateCurrentUsers();
        } else {
          this.errorMessage.code = "Erro ao obter Clientes";
              this.errorMessage.message = "Ocorreu um erro ao obter Clientes. Tente novamente mais tarde.";
              this.showErrorModal = true
        }
      } catch (error) {
        console.error("Erro:", error);
        switch (error.response ? error.response.status : null) {
          case 401:
          this.errorMessage.code = "Token Inválido ou Expirado";
          this.errorMessage.message = "Sua sessão expirou. Faça login novamente.";
            break;
          case 403:
            this.errorMessage.code = "Acesso negado";
            this.errorMessage.message = "Você não tem permissão para realizar esta ação.";
            break;
          case 404:
            this.errorMessage.code = "Recurso não encontrado";
            this.errorMessage.message = "O recurso solicitado não foi encontrado.";
            break;
          default:
          if (error.response) {
              this.errorMessage.code = "Erro do Servidor";
              this.errorMessage.message = "Ocorreu um erro no servidor. Tente novamente mais tarde.";
            } else if (error.request) {
              this.errorMessage.code = "Sem Resposta do Servidor";
              this.errorMessage.message = "Não foi possível obter resposta do servidor. Verifique sua conexão de rede.";
            } else {
              this.errorMessage.code = "Erro na Requisição";
              this.errorMessage.message = "Ocorreu um erro ao enviar a requisição. Tente novamente.";
            }
            break;
        }
          this.showErrorModal = true;
      } finally {
        this.isLoading = false;
      }
    },
    updateCurrentUsers() {
      const startIndex = (this.currentPage - 1) * this.usersPerPage;
      const endIndex = startIndex + this.usersPerPage;
      this.currentUsers = this.users.slice(startIndex, endIndex);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updateCurrentUsers();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updateCurrentUsers();
      }
    },
  },
  mounted() {
    this.listUsers();
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
