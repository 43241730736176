<template>
  <div>
    <Sidebar
      :sidebarOpen="sidebarOpen"
      :logoSrc="logoSrc"
      :sidebarGroups="sidebarGroups"
    />
    <Navbar
      :showDefaultNavbar="showDefaultNavbar"
      :showDropDown="showDropDown"
      :toggleDrop="toggleDrop"
      :avatarSrc="avatarSrc"
      :userName="userName"
      :userRole="userRole"
    />
    <div class="sm:ml-64 p-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/master/Sidebar/Main.vue";
import Navbar from "@/components/master/Navbar.vue";

export default {
  computed: {
    showDefaultNavbar() {
      return this.$route.meta.showDefaultNavbar !== false;
    },
  },
  data() {
     const roles = JSON.parse(localStorage.getItem("roles"));
    if(roles[0]=="administrator"){
    return {
      sidebarOpen: true,
      showDropDown: false,
      logoSrc: require("../../assets/img/PNG/Logo sem fundo/Horizontal logo sem slogan.png"),
      avatarSrc: require("../../assets/img/avatar/avatar.jpeg"),
      userName: "Alduxer",
      userRole: roles[0],
      sidebarGroups: [
        {
          title: "",
          items: [
            {
              route: "/",
              text: "Dashboard geral",
              showNotifications: false,
              notificationCount: 0,
            },
          ],
        },
        {
          title: "Módulos",
          items: [
            {
              route: "/users",
              text: "Usuários",
              showNotifications: false,
              notificationCount: 0,
            },
            {
              route: "/clients",
              text: "Clientes",
              showNotifications: false,
              notificationCount: 0,
            },
             {
              route: "/petitions",
              text: "Solicitações",
              showNotifications: false,
              notificationCount: 0,
            },
            {
              route: "/drivers",
              text: "Motoristas",
              showNotifications: false,
              notificationCount: 0,
            },
            /*{
              route: "/solicitations",
              text: "Solicitações",
              showNotifications: false,
              notificationCount: 0,
            },*/
          ],
        },
        {
          title: "Finanças",
          items: [
            {
              route: "/outflows",
              text: "Saídas",
              showNotifications: false,
              notificationCount: 0,
            },
            {
              route: "/inflows",
              text: "Entradas",
              showNotifications: false,
              notificationCount: 0,
            },
          ],
        },
        {
          title: "Sistema WEWA",
          items: [
            {
              route: "/support",
              text: "Relatórios",
              showNotifications: false,
              notificationCount: 0,
            },
            {
              route: "/support",
              text: "Suporte",
              showNotifications: true,
              notificationCount: 0,
            },
          ],
        },
      ],
    };
    }
  },
  components: {
    Sidebar,
    Navbar,
  },
//   methods: {
//     toggleDrop() {
//       this.showDropDown = !this.showDropDown;
//     },
//     startCount() {
//   const checkExpiration = () => {
//     const loginTime = localStorage.getItem("loginTime");

//     if (loginTime) {
//       const currentTime = new Date().getTime();
//       const loginTimestamp = new Date(loginTime).getTime();
//       const elapsedTime = currentTime - loginTimestamp;
//       const maxSessionTime = 3600 * 1000;

//       if (elapsedTime > maxSessionTime) {
//         this.logout();
//       }
//     }
//   };

//   setInterval(checkExpiration, 1000);
// },

//     logout() {
//       console.log("Tempo de sessão expirado. Realizando logout...");
//       localStorage.clear();
//       this.$router.push("/login");
//     },
//   },
//   mounted() {
//     this.startCount();
//   },
};
</script>

<style>
</style>
